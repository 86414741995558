


















import {Component, VModel, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import ClientService from "@/services/ClientService";
import ClientTab from "@/components/tabs/ClientTab.vue";
import AddressTab from "@/components/tabs/AddressTab.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PromoService from "@/services/PromoService";
import ClientRegisterTab from "@/components/tabs/ClientRegisterTab.vue";
import LangModule from "@/store/LangModule";

@Component
export default class TimeButtonPicker extends Vue {


  @VModel({ type: String }) time!: string
  lang: any = getModule(LangModule).lang
  loading: boolean = false
  picker = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

}
